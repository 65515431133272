import {
  Center,
  Container,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import * as logos from '../components/BrandLogos'
import Layout from '../components/Base/Layout'
import Disclaimer from '../components/Sections/Disclaimer'

const ShopByBrand = () => {

    return (
        <Layout>
         <Container maxW="container.xl" py={{ base: '12', md: '16' }}>
        <Stack spacing="8">
            <Text
                fontSize={{ base: 'md', md: 'lg' }}
                fontWeight="medium"
                color="gray.900"
                textAlign="center"
            >
                Shop Premium Brands
            </Text>
            <SimpleGrid gap={{ base: '4', md: '6' }} columns={{ base: 1, sm: 2, md: 3 }}>
                {Object.entries(logos).map(([name, Logo]) => (
                    <Center
                        key={name}
                        bg="white"
                        py={{ base: '4', md: '8' }}
                        boxShadow={mode('sm', 'sm-dark')}
                        borderRadius="lg"
                        boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);"
                    >
                        <Logo h={{ base: '8', md: '10' }} maxW="180px" fill="emphasized" />
                    </Center>
                ))}
            </SimpleGrid>
        </Stack>
            </Container>
            <Disclaimer></Disclaimer>
    </Layout>
    )
}

export default ShopByBrand