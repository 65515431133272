import { chakra, HTMLChakraProps } from '@chakra-ui/react'
import * as React from 'react'

export const Logo1 = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 168 41" fill="currentColor" {...props}>
    <path d="M49.2775 28.9524H61.2295V25.3524H53.5255V11.7924H49.2775V28.9524Z" />
    <path d="M68.3107 26.5524C66.6067 26.5524 65.7187 25.0644 65.7187 22.8324C65.7187 20.6004 66.6067 19.0884 68.3107 19.0884C70.0147 19.0884 70.9267 20.6004 70.9267 22.8324C70.9267 25.0644 70.0147 26.5524 68.3107 26.5524ZM68.3347 29.3364C72.2947 29.3364 74.8867 26.5284 74.8867 22.8324C74.8867 19.1364 72.2947 16.3284 68.3347 16.3284C64.3987 16.3284 61.7587 19.1364 61.7587 22.8324C61.7587 26.5284 64.3987 29.3364 68.3347 29.3364Z" />
    <path d="M81.7411 33.2004C83.5651 33.2004 85.1731 32.7924 86.2531 31.8324C87.2371 30.9444 87.8851 29.6004 87.8851 27.7524V16.6644H84.1411V17.9844H84.0931C83.3731 16.9524 82.2691 16.3044 80.6371 16.3044C77.5891 16.3044 75.4771 18.8484 75.4771 22.4484C75.4771 26.2164 78.0451 28.2804 80.8051 28.2804C82.2931 28.2804 83.2291 27.6804 83.9491 26.8644H84.0451V28.0884C84.0451 29.5764 83.3491 30.4404 81.6931 30.4404C80.3971 30.4404 79.7491 29.8884 79.5331 29.2404H75.7411C76.1251 31.8084 78.3571 33.2004 81.7411 33.2004ZM81.7171 25.3764C80.2531 25.3764 79.2931 24.1764 79.2931 22.3284C79.2931 20.4564 80.2531 19.2564 81.7171 19.2564C83.3491 19.2564 84.2131 20.6484 84.2131 22.3044C84.2131 24.0324 83.4211 25.3764 81.7171 25.3764Z" />
    <path d="M95.5835 26.5524C93.8795 26.5524 92.9915 25.0644 92.9915 22.8324C92.9915 20.6004 93.8795 19.0884 95.5835 19.0884C97.2875 19.0884 98.1995 20.6004 98.1995 22.8324C98.1995 25.0644 97.2875 26.5524 95.5835 26.5524ZM95.6075 29.3364C99.5675 29.3364 102.159 26.5284 102.159 22.8324C102.159 19.1364 99.5675 16.3284 95.6075 16.3284C91.6715 16.3284 89.0315 19.1364 89.0315 22.8324C89.0315 26.5284 91.6715 29.3364 95.6075 29.3364Z" />
    <path d="M103.302 28.9524H107.214V16.6644H103.302V28.9524ZM103.302 14.9604H107.214V11.7924H103.302V14.9604Z" />
    <path d="M108.911 33.0084H112.823V27.6804H112.871C113.639 28.7124 114.767 29.3364 116.351 29.3364C119.567 29.3364 121.703 26.7924 121.703 22.8084C121.703 19.1124 119.711 16.3044 116.447 16.3044C114.767 16.3044 113.567 17.0484 112.727 18.1524H112.655V16.6644H108.911V33.0084ZM115.343 26.3124C113.663 26.3124 112.703 24.9444 112.703 22.9524C112.703 20.9604 113.567 19.4484 115.271 19.4484C116.951 19.4484 117.743 20.8404 117.743 22.9524C117.743 25.0404 116.831 26.3124 115.343 26.3124Z" />
    <path d="M128.072 29.3364C131.288 29.3364 133.664 27.9444 133.664 25.2564C133.664 22.1124 131.12 21.5604 128.96 21.2004C127.4 20.9124 126.008 20.7924 126.008 19.9284C126.008 19.1604 126.752 18.8004 127.712 18.8004C128.792 18.8004 129.536 19.1364 129.68 20.2404H133.28C133.088 17.8164 131.216 16.3044 127.736 16.3044C124.832 16.3044 122.432 17.6484 122.432 20.2404C122.432 23.1204 124.712 23.6964 126.848 24.0564C128.48 24.3444 129.968 24.4644 129.968 25.5684C129.968 26.3604 129.224 26.7924 128.048 26.7924C126.752 26.7924 125.936 26.1924 125.792 24.9684H122.096C122.216 27.6804 124.472 29.3364 128.072 29.3364Z" />
    <path d="M138.978 29.3124C140.682 29.3124 141.762 28.6404 142.65 27.4404H142.722V28.9524H146.466V16.6644H142.554V23.5284C142.554 24.9924 141.738 26.0004 140.394 26.0004C139.146 26.0004 138.546 25.2564 138.546 23.9124V16.6644H134.658V24.7284C134.658 27.4644 136.146 29.3124 138.978 29.3124Z" />
    <path d="M148.168 28.9524H152.08V22.0644C152.08 20.6004 152.8 19.5684 154.024 19.5684C155.2 19.5684 155.752 20.3364 155.752 21.6564V28.9524H159.664V22.0644C159.664 20.6004 160.36 19.5684 161.608 19.5684C162.784 19.5684 163.336 20.3364 163.336 21.6564V28.9524H167.248V20.9604C167.248 18.2004 165.856 16.3044 163.072 16.3044C161.488 16.3044 160.168 16.9764 159.208 18.4644H159.16C158.536 17.1444 157.312 16.3044 155.704 16.3044C153.928 16.3044 152.752 17.1444 151.984 18.4164H151.912V16.6644H148.168V28.9524Z" />
    <path
      d="M25.4099 1.97689L21.4769 0.923031L18.1625 13.2926L15.1702 2.12527L11.2371 3.17913L14.4701 15.2446L6.41746 7.19201L3.53827 10.0712L12.371 18.904L1.37124 15.9566L0.317383 19.8896L12.336 23.11C12.1984 22.5165 12.1256 21.8981 12.1256 21.2627C12.1256 16.7651 15.7716 13.1191 20.2692 13.1191C24.7668 13.1191 28.4128 16.7651 28.4128 21.2627C28.4128 21.894 28.3409 22.5086 28.205 23.0986L39.1277 26.0253L40.1815 22.0923L28.1151 18.8591L39.1156 15.9115L38.0617 11.9785L25.9958 15.2115L34.0484 7.15895L31.1692 4.27976L22.459 12.99L25.4099 1.97689Z"
      fill="#F97316"
    />
    <path
      d="M28.1943 23.1444C27.8571 24.57 27.1452 25.8507 26.1684 26.8768L34.0814 34.7899L36.9606 31.9107L28.1943 23.1444Z"
      fill="#F97316"
    />
    <path
      d="M26.0884 26.9596C25.0998 27.9693 23.8505 28.7228 22.4495 29.1111L25.3289 39.8571L29.2619 38.8032L26.0884 26.9596Z"
      fill="#F97316"
    />
    <path
      d="M22.3026 29.1504C21.6526 29.3175 20.9713 29.4063 20.2692 29.4063C19.517 29.4063 18.7886 29.3043 18.0971 29.1134L15.2151 39.8692L19.1481 40.923L22.3026 29.1504Z"
      fill="#F97316"
    />
    <path
      d="M17.9581 29.0737C16.5785 28.6661 15.3514 27.903 14.383 26.8904L6.45052 34.8229L9.32971 37.7021L17.9581 29.0737Z"
      fill="#F97316"
    />
    <path
      d="M14.3168 26.8203C13.365 25.8013 12.6717 24.5377 12.3417 23.1341L1.38334 26.0704L2.43719 30.0034L14.3168 26.8203Z"
      fill="#F97316"
    />
  </chakra.svg>
)

export const Logo2 = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 167 41" fill="currentColor" {...props}>
    <path d="M48.6307 28.7936H60.5827V25.1936H52.8787V11.6336H48.6307V28.7936Z" />
    <path d="M67.6639 26.3936C65.9599 26.3936 65.0719 24.9056 65.0719 22.6736C65.0719 20.4416 65.9599 18.9296 67.6639 18.9296C69.3679 18.9296 70.2799 20.4416 70.2799 22.6736C70.2799 24.9056 69.3679 26.3936 67.6639 26.3936ZM67.6879 29.1776C71.6479 29.1776 74.2399 26.3696 74.2399 22.6736C74.2399 18.9776 71.6479 16.1696 67.6879 16.1696C63.7519 16.1696 61.1119 18.9776 61.1119 22.6736C61.1119 26.3696 63.7519 29.1776 67.6879 29.1776Z" />
    <path d="M81.0943 33.0416C82.9183 33.0416 84.5263 32.6336 85.6063 31.6736C86.5903 30.7856 87.2383 29.4416 87.2383 27.5936V16.5056H83.4943V17.8256H83.4463C82.7263 16.7936 81.6223 16.1456 79.9903 16.1456C76.9423 16.1456 74.8303 18.6896 74.8303 22.2896C74.8303 26.0576 77.3983 28.1216 80.1583 28.1216C81.6463 28.1216 82.5823 27.5216 83.3023 26.7056H83.3983V27.9296C83.3983 29.4176 82.7023 30.2816 81.0463 30.2816C79.7503 30.2816 79.1023 29.7296 78.8863 29.0816H75.0943C75.4783 31.6496 77.7103 33.0416 81.0943 33.0416ZM81.0703 25.2176C79.6063 25.2176 78.6463 24.0176 78.6463 22.1696C78.6463 20.2976 79.6063 19.0976 81.0703 19.0976C82.7023 19.0976 83.5663 20.4896 83.5663 22.1456C83.5663 23.8736 82.7743 25.2176 81.0703 25.2176Z" />
    <path d="M94.9367 26.3936C93.2327 26.3936 92.3447 24.9056 92.3447 22.6736C92.3447 20.4416 93.2327 18.9296 94.9367 18.9296C96.6407 18.9296 97.5527 20.4416 97.5527 22.6736C97.5527 24.9056 96.6407 26.3936 94.9367 26.3936ZM94.9607 29.1776C98.9207 29.1776 101.513 26.3696 101.513 22.6736C101.513 18.9776 98.9207 16.1696 94.9607 16.1696C91.0247 16.1696 88.3847 18.9776 88.3847 22.6736C88.3847 26.3696 91.0247 29.1776 94.9607 29.1776Z" />
    <path d="M102.655 28.7936H106.567V16.5056H102.655V28.7936ZM102.655 14.8016H106.567V11.6336H102.655V14.8016Z" />
    <path d="M108.264 32.8496H112.176V27.5216H112.224C112.992 28.5536 114.12 29.1776 115.704 29.1776C118.92 29.1776 121.056 26.6336 121.056 22.6496C121.056 18.9536 119.064 16.1456 115.8 16.1456C114.12 16.1456 112.92 16.8896 112.08 17.9936H112.008V16.5056H108.264V32.8496ZM114.696 26.1536C113.016 26.1536 112.056 24.7856 112.056 22.7936C112.056 20.8016 112.92 19.2896 114.624 19.2896C116.304 19.2896 117.096 20.6816 117.096 22.7936C117.096 24.8816 116.184 26.1536 114.696 26.1536Z" />
    <path d="M127.426 29.1776C130.642 29.1776 133.018 27.7856 133.018 25.0976C133.018 21.9536 130.474 21.4016 128.314 21.0416C126.754 20.7536 125.362 20.6336 125.362 19.7696C125.362 19.0016 126.106 18.6416 127.066 18.6416C128.146 18.6416 128.89 18.9776 129.034 20.0816H132.634C132.442 17.6576 130.57 16.1456 127.09 16.1456C124.186 16.1456 121.786 17.4896 121.786 20.0816C121.786 22.9616 124.066 23.5376 126.202 23.8976C127.834 24.1856 129.322 24.3056 129.322 25.4096C129.322 26.2016 128.578 26.6336 127.402 26.6336C126.106 26.6336 125.29 26.0336 125.146 24.8096H121.45C121.57 27.5216 123.826 29.1776 127.426 29.1776Z" />
    <path d="M138.331 29.1536C140.035 29.1536 141.115 28.4816 142.003 27.2816H142.075V28.7936H145.819V16.5056H141.907V23.3696C141.907 24.8336 141.091 25.8416 139.747 25.8416C138.499 25.8416 137.899 25.0976 137.899 23.7536V16.5056H134.011V24.5696C134.011 27.3056 135.499 29.1536 138.331 29.1536Z" />
    <path d="M147.521 28.7936H151.433V21.9056C151.433 20.4416 152.153 19.4096 153.377 19.4096C154.553 19.4096 155.105 20.1776 155.105 21.4976V28.7936H159.017V21.9056C159.017 20.4416 159.713 19.4096 160.961 19.4096C162.137 19.4096 162.689 20.1776 162.689 21.4976V28.7936H166.601V20.8016C166.601 18.0416 165.209 16.1456 162.425 16.1456C160.841 16.1456 159.521 16.8176 158.561 18.3056H158.513C157.889 16.9856 156.665 16.1456 155.057 16.1456C153.281 16.1456 152.105 16.9856 151.337 18.2576H151.265V16.5056H147.521V28.7936Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.65417 3.89149C7.22351 4.89185 5.92981 6.0746 4.80676 7.40602C9.39606 6.97995 15.2266 7.67567 21.9958 11.0603C29.2244 14.6745 35.0452 14.7967 39.0962 14.0153C38.7286 12.9024 38.2658 11.8328 37.7177 10.816C33.0804 11.3051 27.1354 10.6577 20.207 7.1936C15.8074 4.9938 11.9292 4.08763 8.65417 3.89149ZM35.0088 6.96027C31.3467 2.86862 26.0248 0.293625 20.1014 0.293625C18.3619 0.293625 16.6741 0.515732 15.0651 0.933105C17.2443 1.52771 19.5593 2.39761 21.9958 3.61589C27.0684 6.15215 31.4478 6.96878 35.0088 6.96027ZM39.9623 17.9217C35.0683 18.8881 28.3102 18.6896 20.207 14.638C12.6314 10.8502 6.60187 10.8979 2.53534 11.8016C2.32544 11.8482 2.12048 11.8972 1.92047 11.9482C1.38806 13.1061 0.963074 14.3237 0.658142 15.5881C0.983826 15.5011 1.32037 15.4184 1.6676 15.3412C6.60101 14.2449 13.5715 14.2925 21.9958 18.5047C29.5715 22.2925 35.601 22.2448 39.6676 21.3411C39.8069 21.3102 39.9442 21.2782 40.0792 21.2452C40.094 20.9299 40.1014 20.6126 40.1014 20.2936C40.1014 19.4911 40.0542 18.6996 39.9623 17.9217ZM39.4262 25.4659C34.5797 26.3132 28.0184 25.988 20.207 22.0824C12.6314 18.2946 6.60187 18.3423 2.53534 19.246C1.63269 19.4465 0.820679 19.6908 0.10437 19.9487C0.102417 20.0634 0.10144 20.1784 0.10144 20.2936C0.10144 31.3393 9.05573 40.2936 20.1014 40.2936C29.3585 40.2936 37.1467 34.0045 39.4262 25.4659Z"
      fill="#7F57F1"
    />
  </chakra.svg>
)

export const Logo3 = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 170 41" fill="currentColor" {...props}>
    <path d="M51.2775 29.3232H63.2295V25.7232H55.5255V12.1632H51.2775V29.3232Z" />
    <path d="M70.3106 26.9232C68.6066 26.9232 67.7186 25.4352 67.7186 23.2032C67.7186 20.9712 68.6066 19.4592 70.3106 19.4592C72.0146 19.4592 72.9266 20.9712 72.9266 23.2032C72.9266 25.4352 72.0146 26.9232 70.3106 26.9232ZM70.3346 29.7072C74.2946 29.7072 76.8866 26.8992 76.8866 23.2032C76.8866 19.5072 74.2946 16.6992 70.3346 16.6992C66.3986 16.6992 63.7586 19.5072 63.7586 23.2032C63.7586 26.8992 66.3986 29.7072 70.3346 29.7072Z" />
    <path d="M83.741 33.5712C85.565 33.5712 87.173 33.1632 88.253 32.2032C89.237 31.3152 89.885 29.9712 89.885 28.1232V17.0352H86.141V18.3552H86.093C85.373 17.3232 84.269 16.6752 82.637 16.6752C79.589 16.6752 77.477 19.2192 77.477 22.8192C77.477 26.5872 80.045 28.6512 82.805 28.6512C84.293 28.6512 85.229 28.0512 85.949 27.2352H86.045V28.4592C86.045 29.9472 85.349 30.8112 83.693 30.8112C82.397 30.8112 81.749 30.2592 81.533 29.6112H77.741C78.125 32.1792 80.357 33.5712 83.741 33.5712ZM83.717 25.7472C82.253 25.7472 81.293 24.5472 81.293 22.6992C81.293 20.8272 82.253 19.6272 83.717 19.6272C85.349 19.6272 86.213 21.0192 86.213 22.6752C86.213 24.4032 85.421 25.7472 83.717 25.7472Z" />
    <path d="M97.5834 26.9232C95.8794 26.9232 94.9914 25.4352 94.9914 23.2032C94.9914 20.9712 95.8794 19.4592 97.5834 19.4592C99.2874 19.4592 100.199 20.9712 100.199 23.2032C100.199 25.4352 99.2874 26.9232 97.5834 26.9232ZM97.6074 29.7072C101.567 29.7072 104.159 26.8992 104.159 23.2032C104.159 19.5072 101.567 16.6992 97.6074 16.6992C93.6714 16.6992 91.0314 19.5072 91.0314 23.2032C91.0314 26.8992 93.6714 29.7072 97.6074 29.7072Z" />
    <path d="M105.302 29.3232H109.214V17.0352H105.302V29.3232ZM105.302 15.3312H109.214V12.1632H105.302V15.3312Z" />
    <path d="M110.911 33.3792H114.823V28.0512H114.871C115.639 29.0832 116.767 29.7072 118.351 29.7072C121.567 29.7072 123.703 27.1632 123.703 23.1792C123.703 19.4832 121.711 16.6752 118.447 16.6752C116.767 16.6752 115.567 17.4192 114.727 18.5232H114.655V17.0352H110.911V33.3792ZM117.343 26.6832C115.663 26.6832 114.703 25.3152 114.703 23.3232C114.703 21.3312 115.567 19.8192 117.271 19.8192C118.951 19.8192 119.743 21.2112 119.743 23.3232C119.743 25.4112 118.831 26.6832 117.343 26.6832Z" />
    <path d="M130.072 29.7072C133.288 29.7072 135.664 28.3152 135.664 25.6272C135.664 22.4832 133.12 21.9312 130.96 21.5712C129.4 21.2832 128.008 21.1632 128.008 20.2992C128.008 19.5312 128.752 19.1712 129.712 19.1712C130.792 19.1712 131.536 19.5072 131.68 20.6112H135.28C135.088 18.1872 133.216 16.6752 129.736 16.6752C126.832 16.6752 124.432 18.0192 124.432 20.6112C124.432 23.4912 126.712 24.0672 128.848 24.4272C130.48 24.7152 131.968 24.8352 131.968 25.9392C131.968 26.7312 131.224 27.1632 130.048 27.1632C128.752 27.1632 127.936 26.5632 127.792 25.3392H124.096C124.216 28.0512 126.472 29.7072 130.072 29.7072Z" />
    <path d="M140.978 29.6832C142.682 29.6832 143.762 29.0112 144.65 27.8112H144.722V29.3232H148.466V17.0352H144.554V23.8992C144.554 25.3632 143.738 26.3712 142.394 26.3712C141.146 26.3712 140.546 25.6272 140.546 24.2832V17.0352H136.658V25.0992C136.658 27.8352 138.146 29.6832 140.978 29.6832Z" />
    <path d="M150.168 29.3232H154.08V22.4352C154.08 20.9712 154.8 19.9392 156.024 19.9392C157.2 19.9392 157.752 20.7072 157.752 22.0272V29.3232H161.664V22.4352C161.664 20.9712 162.36 19.9392 163.608 19.9392C164.784 19.9392 165.336 20.7072 165.336 22.0272V29.3232H169.248V21.3312C169.248 18.5712 167.856 16.6752 165.072 16.6752C163.488 16.6752 162.168 17.3472 161.208 18.8352H161.16C160.536 17.5152 159.312 16.6752 157.704 16.6752C155.928 16.6752 154.752 17.5152 153.984 18.7872H153.912V17.0352H150.168V29.3232Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1014 40.3232C31.1472 40.3232 40.1014 31.3689 40.1014 20.3232C40.1014 9.27754 31.1472 0.323242 20.1014 0.323242C9.05573 0.323242 0.10144 9.27754 0.10144 20.3232C0.10144 31.3689 9.05573 40.3232 20.1014 40.3232ZM23.1884 15.7758C24.8932 14.0709 24.8932 11.3068 23.1884 9.6019C21.4835 7.89702 18.7194 7.89702 17.0145 9.6019C15.3097 11.3068 15.3097 14.0709 17.0145 15.7758L20.1014 18.8627L23.1884 15.7758ZM24.6489 23.4102C26.3538 25.1151 29.1179 25.1151 30.8228 23.4102C32.5276 21.7053 32.5276 18.9412 30.8228 17.2363C29.1179 15.5315 26.3538 15.5315 24.6489 17.2363L21.562 20.3233L24.6489 23.4102ZM23.1884 31.0446C24.8932 29.3397 24.8932 26.5756 23.1884 24.8707L20.1014 21.7838L17.0145 24.8707C15.3097 26.5756 15.3097 29.3397 17.0145 31.0446C18.7194 32.7495 21.4835 32.7495 23.1884 31.0446ZM9.38007 23.4102C7.67523 21.7053 7.67523 18.9412 9.38007 17.2363C11.085 15.5315 13.8491 15.5315 15.554 17.2363L18.6409 20.3233L15.554 23.4102C13.8491 25.1151 11.085 25.1151 9.38007 23.4102Z"
      fill="#7F57F1"
    />
  </chakra.svg>
)

export const Logo4 = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 153 38" fill="currentColor" {...props}>
    <path
      d="M0.878785 28.1818L0.878788 9.39393L10.2727 14.6128L10.2727 23.0151L17.7879 27.2424L25.303 23.0151L25.303 14.6128L34.697 9.39393L34.697 28.1818L17.7879 37.5757L0.878785 28.1818Z"
      fill="#192657"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.57576 12.0034L0.878784 9.39394L17.7879 0L34.697 9.39394L30 12.0034L30 25.8333L17.7879 32.8788L5.57575 25.8333L5.57576 12.0034ZM29.4912 12.2861L25.303 14.6128V14.5606L17.7879 10.3333L10.2727 14.5606V14.6128L6.08459 12.2861L6.08459 25.5443L17.7879 32.2962L29.4912 25.5443L29.4912 12.2861Z"
      fill="url(#paint500_linear_1501_1259)"
    />
    <path d="M43.7001 26.697V10.9152H46.6761V23.9915H53.8907V26.697H43.7001Z" />
    <path d="M60.3159 27.0126C59.2037 27.0126 58.2042 26.7872 57.3174 26.3362C56.4306 25.8853 55.7317 25.2315 55.2207 24.3748C54.7096 23.5181 54.4541 22.4885 54.4541 21.2861V20.9253C54.4541 19.7229 54.7096 18.6933 55.2207 17.8366C55.7317 16.9799 56.4306 16.3261 57.3174 15.8752C58.2042 15.4242 59.2037 15.1988 60.3159 15.1988C61.4282 15.1988 62.4277 15.4242 63.3145 15.8752C64.2013 16.3261 64.9002 16.9799 65.4112 17.8366C65.9222 18.6933 66.1778 19.7229 66.1778 20.9253V21.2861C66.1778 22.4885 65.9222 23.5181 65.4112 24.3748C64.9002 25.2315 64.2013 25.8853 63.3145 26.3362C62.4277 26.7872 61.4282 27.0126 60.3159 27.0126ZM60.3159 24.4875C61.1877 24.4875 61.9092 24.2095 62.4803 23.6533C63.0515 23.0822 63.337 22.2705 63.337 21.2184V20.993C63.337 19.9409 63.0515 19.1367 62.4803 18.5806C61.9242 18.0095 61.2027 17.7239 60.3159 17.7239C59.4442 17.7239 58.7227 18.0095 58.1516 18.5806C57.5804 19.1367 57.2949 19.9409 57.2949 20.993V21.2184C57.2949 22.2705 57.5804 23.0822 58.1516 23.6533C58.7227 24.2095 59.4442 24.4875 60.3159 24.4875Z" />
    <path d="M67.2443 21.1508V20.7901C67.2443 19.6177 67.4772 18.6182 67.9432 17.7915C68.4091 16.9498 69.0254 16.311 69.7919 15.8752C70.5735 15.4242 71.4227 15.1988 72.3395 15.1988C73.3616 15.1988 74.1357 15.3792 74.6617 15.7399C75.1878 16.1006 75.5711 16.4764 75.8115 16.8672H76.2174V15.5144H79.013V28.681C79.013 29.4475 78.7875 30.0562 78.3366 30.5072C77.8857 30.9731 77.2845 31.2061 76.533 31.2061H69.0479V28.7261H75.541C75.9618 28.7261 76.1723 28.5006 76.1723 28.0497V25.1413H75.7664C75.6161 25.3818 75.4057 25.6298 75.1352 25.8853C74.8646 26.1258 74.5039 26.3287 74.053 26.4941C73.6021 26.6594 73.0309 26.7421 72.3395 26.7421C71.4227 26.7421 70.5735 26.5241 69.7919 26.0882C69.0254 25.6373 68.4091 24.9985 67.9432 24.1719C67.4772 23.3302 67.2443 22.3232 67.2443 21.1508ZM73.1512 24.2621C74.0229 24.2621 74.7519 23.984 75.3381 23.4279C75.9243 22.8718 76.2174 22.0902 76.2174 21.0832V20.8577C76.2174 19.8356 75.9243 19.0541 75.3381 18.513C74.7669 17.9569 74.038 17.6788 73.1512 17.6788C72.2794 17.6788 71.5504 17.9569 70.9643 18.513C70.3781 19.0541 70.085 19.8356 70.085 20.8577V21.0832C70.085 22.0902 70.3781 22.8718 70.9643 23.4279C71.5504 23.984 72.2794 24.2621 73.1512 24.2621Z" />
    <path d="M86.4687 27.0126C85.3564 27.0126 84.3569 26.7872 83.4701 26.3362C82.5833 25.8853 81.8844 25.2315 81.3734 24.3748C80.8624 23.5181 80.6069 22.4885 80.6069 21.2861V20.9253C80.6069 19.7229 80.8624 18.6933 81.3734 17.8366C81.8844 16.9799 82.5833 16.3261 83.4701 15.8752C84.3569 15.4242 85.3564 15.1988 86.4687 15.1988C87.5809 15.1988 88.5804 15.4242 89.4672 15.8752C90.354 16.3261 91.0529 16.9799 91.5639 17.8366C92.075 18.6933 92.3305 19.7229 92.3305 20.9253V21.2861C92.3305 22.4885 92.075 23.5181 91.5639 24.3748C91.0529 25.2315 90.354 25.8853 89.4672 26.3362C88.5804 26.7872 87.5809 27.0126 86.4687 27.0126ZM86.4687 24.4875C87.3404 24.4875 88.0619 24.2095 88.633 23.6533C89.2042 23.0822 89.4898 22.2705 89.4898 21.2184V20.993C89.4898 19.9409 89.2042 19.1367 88.633 18.5806C88.0769 18.0095 87.3555 17.7239 86.4687 17.7239C85.5969 17.7239 84.8755 18.0095 84.3043 18.5806C83.7332 19.1367 83.4476 19.9409 83.4476 20.993V21.2184C83.4476 22.2705 83.7332 23.0822 84.3043 23.6533C84.8755 24.2095 85.5969 24.4875 86.4687 24.4875Z" />
    <path d="M93.9381 26.697V15.5144H96.7788V26.697H93.9381ZM95.3584 14.2068C94.8474 14.2068 94.4115 14.0415 94.0508 13.7108C93.7051 13.3801 93.5323 12.9442 93.5323 12.4032C93.5323 11.8621 93.7051 11.4262 94.0508 11.0955C94.4115 10.7649 94.8474 10.5995 95.3584 10.5995C95.8845 10.5995 96.3204 10.7649 96.6661 11.0955C97.0118 11.4262 97.1846 11.8621 97.1846 12.4032C97.1846 12.9442 97.0118 13.3801 96.6661 13.7108C96.3204 14.0415 95.8845 14.2068 95.3584 14.2068Z" />
    <path d="M98.9122 31.2061V15.5144H101.708V16.8672H102.114C102.369 16.4313 102.767 16.048 103.309 15.7173C103.85 15.3716 104.624 15.1988 105.631 15.1988C106.533 15.1988 107.367 15.4242 108.133 15.8752C108.9 16.311 109.516 16.9573 109.982 17.8141C110.448 18.6708 110.681 19.7079 110.681 20.9253V21.2861C110.681 22.5035 110.448 23.5406 109.982 24.3973C109.516 25.2541 108.9 25.9079 108.133 26.3588C107.367 26.7947 106.533 27.0126 105.631 27.0126C104.954 27.0126 104.383 26.9299 103.917 26.7646C103.466 26.6143 103.098 26.4189 102.813 26.1784C102.542 25.9229 102.324 25.6674 102.159 25.4119H101.753V31.2061H98.9122ZM104.774 24.5326C105.661 24.5326 106.39 24.2545 106.961 23.6984C107.547 23.1273 107.84 22.3006 107.84 21.2184V20.993C107.84 19.9108 107.547 19.0916 106.961 18.5355C106.375 17.9644 105.646 17.6788 104.774 17.6788C103.902 17.6788 103.173 17.9644 102.587 18.5355C102.001 19.0916 101.708 19.9108 101.708 20.993V21.2184C101.708 22.3006 102.001 23.1273 102.587 23.6984C103.173 24.2545 103.902 24.5326 104.774 24.5326Z" />
    <path d="M116.919 27.0126C115.461 27.0126 114.266 26.697 113.334 26.0657C112.403 25.4344 111.839 24.5326 111.643 23.3602L114.259 22.6839C114.364 23.2099 114.537 23.6233 114.777 23.9239C115.033 24.2245 115.341 24.4424 115.702 24.5777C116.077 24.6979 116.483 24.7581 116.919 24.7581C117.58 24.7581 118.069 24.6453 118.385 24.4199C118.7 24.1794 118.858 23.8863 118.858 23.5406C118.858 23.1949 118.708 22.9319 118.407 22.7515C118.107 22.5561 117.626 22.3983 116.964 22.2781L116.333 22.1653C115.551 22.015 114.837 21.8121 114.191 21.5566C113.545 21.2861 113.026 20.9178 112.635 20.4519C112.245 19.9859 112.049 19.3847 112.049 18.6482C112.049 17.536 112.455 16.6868 113.267 16.1006C114.078 15.4994 115.146 15.1988 116.468 15.1988C117.716 15.1988 118.753 15.4769 119.579 16.033C120.406 16.5891 120.947 17.3181 121.203 18.2199L118.565 19.0315C118.445 18.4604 118.197 18.0545 117.821 17.8141C117.46 17.5736 117.009 17.4533 116.468 17.4533C115.927 17.4533 115.514 17.551 115.228 17.7464C114.943 17.9268 114.8 18.1823 114.8 18.513C114.8 18.8737 114.95 19.1442 115.251 19.3246C115.551 19.4899 115.957 19.6177 116.468 19.7079L117.099 19.8206C117.941 19.9709 118.7 20.1738 119.377 20.4293C120.068 20.6698 120.609 21.023 121 21.489C121.406 21.9399 121.609 22.5561 121.609 23.3377C121.609 24.5101 121.18 25.4194 120.323 26.0657C119.482 26.697 118.347 27.0126 116.919 27.0126Z" />
    <path d="M127.344 26.8773C126.473 26.8773 125.706 26.6819 125.045 26.2912C124.399 25.8853 123.895 25.3292 123.534 24.6228C123.174 23.9164 122.993 23.1047 122.993 22.1879V15.5144H125.834V21.9624C125.834 22.8041 126.037 23.4354 126.443 23.8562C126.863 24.2771 127.457 24.4875 128.224 24.4875C129.095 24.4875 129.772 24.2019 130.253 23.6308C130.734 23.0446 130.974 22.233 130.974 21.1959V15.5144H133.815V26.697H131.019V25.2315H130.614C130.433 25.6073 130.095 25.9755 129.599 26.3362C129.103 26.697 128.351 26.8773 127.344 26.8773Z" />
    <path d="M135.962 26.697V15.5144H138.757V16.7319H139.163C139.358 16.3561 139.682 16.033 140.132 15.7624C140.583 15.4769 141.177 15.3341 141.914 15.3341C142.71 15.3341 143.349 15.4919 143.83 15.8075C144.311 16.1081 144.679 16.5064 144.935 17.0024H145.34C145.596 16.5215 145.957 16.1232 146.423 15.8075C146.889 15.4919 147.55 15.3341 148.407 15.3341C149.098 15.3341 149.722 15.4844 150.278 15.785C150.849 16.0705 151.3 16.5139 151.631 17.1152C151.976 17.7013 152.149 18.4453 152.149 19.3472V26.697H149.308V19.5501C149.308 18.9338 149.151 18.4754 148.835 18.1748C148.519 17.8592 148.076 17.7013 147.505 17.7013C146.859 17.7013 146.355 17.9118 145.994 18.3326C145.649 18.7384 145.476 19.3246 145.476 20.0912V26.697H142.635V19.5501C142.635 18.9338 142.477 18.4754 142.162 18.1748C141.846 17.8592 141.403 17.7013 140.831 17.7013C140.185 17.7013 139.682 17.9118 139.321 18.3326C138.975 18.7384 138.802 19.3246 138.802 20.0912V26.697H135.962Z" />
    <defs>
      <linearGradient
        id="paint500_linear_1501_1259"
        x1="5.57575"
        y1="7.30638"
        x2="30.9394"
        y2="7.30639"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#64C2DB" />
        <stop offset="0.307292" stopColor="#7476ED" />
        <stop offset="0.604167" stopColor="#C994DF" />
        <stop offset="1" stopColor="#E56F8C" />
      </linearGradient>
    </defs>
  </chakra.svg>
)

export const Logo5 = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 170 41" fill="currentColor" {...props}>
    <path d="M51.8601 28.138H63.8121V24.538H56.1081V10.978H51.8601V28.138Z" />
    <path d="M70.8932 25.738C69.1892 25.738 68.3012 24.25 68.3012 22.018C68.3012 19.786 69.1892 18.274 70.8932 18.274C72.5972 18.274 73.5092 19.786 73.5092 22.018C73.5092 24.25 72.5972 25.738 70.8932 25.738ZM70.9172 28.522C74.8772 28.522 77.4692 25.714 77.4692 22.018C77.4692 18.322 74.8772 15.514 70.9172 15.514C66.9812 15.514 64.3412 18.322 64.3412 22.018C64.3412 25.714 66.9812 28.522 70.9172 28.522Z" />
    <path d="M84.3237 32.386C86.1477 32.386 87.7557 31.978 88.8357 31.018C89.8197 30.13 90.4677 28.786 90.4677 26.938V15.85H86.7237V17.17H86.6757C85.9557 16.138 84.8517 15.49 83.2197 15.49C80.1717 15.49 78.0597 18.034 78.0597 21.634C78.0597 25.402 80.6277 27.466 83.3877 27.466C84.8757 27.466 85.8117 26.866 86.5317 26.05H86.6277V27.274C86.6277 28.762 85.9317 29.626 84.2757 29.626C82.9797 29.626 82.3317 29.074 82.1157 28.426H78.3237C78.7077 30.994 80.9397 32.386 84.3237 32.386ZM84.2997 24.562C82.8357 24.562 81.8757 23.362 81.8757 21.514C81.8757 19.642 82.8357 18.442 84.2997 18.442C85.9317 18.442 86.7957 19.834 86.7957 21.49C86.7957 23.218 86.0037 24.562 84.2997 24.562Z" />
    <path d="M98.166 25.738C96.462 25.738 95.574 24.25 95.574 22.018C95.574 19.786 96.462 18.274 98.166 18.274C99.87 18.274 100.782 19.786 100.782 22.018C100.782 24.25 99.87 25.738 98.166 25.738ZM98.19 28.522C102.15 28.522 104.742 25.714 104.742 22.018C104.742 18.322 102.15 15.514 98.19 15.514C94.254 15.514 91.614 18.322 91.614 22.018C91.614 25.714 94.254 28.522 98.19 28.522Z" />
    <path d="M105.884 28.138H109.796V15.85H105.884V28.138ZM105.884 14.146H109.796V10.978H105.884V14.146Z" />
    <path d="M111.494 32.194H115.406V26.866H115.454C116.222 27.898 117.35 28.522 118.934 28.522C122.15 28.522 124.286 25.978 124.286 21.994C124.286 18.298 122.294 15.49 119.03 15.49C117.35 15.49 116.15 16.234 115.31 17.338H115.238V15.85H111.494V32.194ZM117.926 25.498C116.246 25.498 115.286 24.13 115.286 22.138C115.286 20.146 116.15 18.634 117.854 18.634C119.534 18.634 120.326 20.026 120.326 22.138C120.326 24.226 119.414 25.498 117.926 25.498Z" />
    <path d="M130.655 28.522C133.871 28.522 136.247 27.13 136.247 24.442C136.247 21.298 133.703 20.746 131.543 20.386C129.983 20.098 128.591 19.978 128.591 19.114C128.591 18.346 129.335 17.986 130.295 17.986C131.375 17.986 132.119 18.322 132.263 19.426H135.863C135.671 17.002 133.799 15.49 130.319 15.49C127.415 15.49 125.015 16.834 125.015 19.426C125.015 22.306 127.295 22.882 129.431 23.242C131.063 23.53 132.551 23.65 132.551 24.754C132.551 25.546 131.807 25.978 130.631 25.978C129.335 25.978 128.519 25.378 128.375 24.154H124.679C124.799 26.866 127.055 28.522 130.655 28.522Z" />
    <path d="M141.561 28.498C143.265 28.498 144.345 27.826 145.233 26.626H145.305V28.138H149.049V15.85H145.137V22.714C145.137 24.178 144.321 25.186 142.977 25.186C141.729 25.186 141.129 24.442 141.129 23.098V15.85H137.241V23.914C137.241 26.65 138.729 28.498 141.561 28.498Z" />
    <path d="M150.75 28.138H154.662V21.25C154.662 19.786 155.382 18.754 156.606 18.754C157.782 18.754 158.334 19.522 158.334 20.842V28.138H162.246V21.25C162.246 19.786 162.942 18.754 164.19 18.754C165.366 18.754 165.918 19.522 165.918 20.842V28.138H169.83V20.146C169.83 17.386 168.438 15.49 165.654 15.49C164.07 15.49 162.75 16.162 161.79 17.65H161.742C161.118 16.33 159.894 15.49 158.286 15.49C156.51 15.49 155.334 16.33 154.566 17.602H154.494V15.85H150.75V28.138Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6841 40.138C31.7298 40.138 40.6841 31.1837 40.6841 20.138C40.6841 9.09234 31.7298 0.138031 20.6841 0.138031C9.63837 0.138031 0.684082 9.09234 0.684082 20.138C0.684082 31.1837 9.63837 40.138 20.6841 40.138ZM26.9234 9.45487C27.2271 8.37608 26.1802 7.73816 25.2241 8.41933L11.8772 17.9276C10.8403 18.6663 11.0034 20.138 12.1222 20.138L15.6368 20.138V20.1108H22.4866L16.9053 22.0801L14.4448 30.8212C14.1411 31.9 15.1879 32.5379 16.1441 31.8567L29.491 22.3485C30.5279 21.6098 30.3647 20.138 29.246 20.138L23.9162 20.138L26.9234 9.45487Z"
      fill="#F15757"
    />
  </chakra.svg>
)
export const Logo6 = (props: HTMLChakraProps<'svg'>) => (
  <chakra.svg viewBox="0 0 161 44" fill="currentColor" {...props}>
    <path d="M54.196 29H57.715V12.44H54.196V29Z" />
    <path d="M64.9338 29.368C68.6138 29.368 71.0288 26.999 71.0288 22.813C71.0288 18.765 68.6828 16.235 64.9338 16.235C61.2538 16.235 58.8618 18.604 58.8618 22.813C58.8618 26.884 61.1848 29.368 64.9338 29.368ZM64.9338 26.516C63.3468 26.516 62.4728 25.205 62.4728 22.813C62.4728 20.398 63.3238 19.087 64.9338 19.087C66.5438 19.087 67.4178 20.421 67.4178 22.813C67.4178 25.228 66.5668 26.516 64.9338 26.516Z" />
    <path d="M76.7538 29.023C78.0878 29.023 79.1918 28.471 79.9738 27.39V29.46C79.9738 31.185 79.1228 31.76 77.8578 31.76C76.6388 31.76 75.6958 31.162 75.5578 30.058H72.0388C72.4758 33.071 74.7528 34.451 77.9038 34.451C81.1468 34.451 83.4928 32.726 83.4928 29.414V16.626H79.9738V17.776C79.2148 16.764 78.1338 16.235 76.7538 16.235C73.6718 16.235 71.6708 18.535 71.6708 22.652C71.6708 26.585 73.6258 29.023 76.7538 29.023ZM77.6048 26.171C76.0868 26.171 75.2818 24.906 75.2818 22.652C75.2818 20.329 76.0868 19.087 77.6048 19.087C79.1458 19.087 79.9738 20.352 79.9738 22.629C79.9738 24.929 79.1688 26.171 77.6048 26.171Z" />
    <path d="M90.7315 29.368C94.4115 29.368 96.8265 26.999 96.8265 22.813C96.8265 18.765 94.4805 16.235 90.7315 16.235C87.0515 16.235 84.6595 18.604 84.6595 22.813C84.6595 26.884 86.9825 29.368 90.7315 29.368ZM90.7315 26.516C89.1445 26.516 88.2705 25.205 88.2705 22.813C88.2705 20.398 89.1215 19.087 90.7315 19.087C92.3415 19.087 93.2155 20.421 93.2155 22.813C93.2155 25.228 92.3645 26.516 90.7315 26.516Z" />
    <path d="M97.9976 15.384H101.517V12.44H97.9976V15.384ZM97.9976 29H101.517V16.626H97.9976V29Z" />
    <path d="M106.711 34.083V27.758C107.447 28.816 108.505 29.368 109.816 29.368C112.967 29.368 115.014 26.999 115.014 22.79C115.014 18.742 113.013 16.235 109.816 16.235C108.528 16.235 107.47 16.833 106.711 17.914V16.626H103.192V34.083H106.711ZM109.08 19.087C110.575 19.087 111.403 20.398 111.403 22.79C111.403 25.205 110.598 26.516 109.08 26.516C107.539 26.516 106.711 25.182 106.711 22.79C106.711 20.398 107.516 19.087 109.08 19.087Z" />
    <path d="M119.24 20.053C119.24 19.386 119.838 18.926 120.758 18.926C122 18.926 122.736 19.455 122.943 20.72H126.439C126.094 17.592 123.84 16.235 120.873 16.235C117.561 16.235 115.882 18.167 115.882 20.237C115.882 25.205 123.38 23.066 123.38 25.458C123.38 26.217 122.759 26.7 121.517 26.7C120.206 26.7 119.263 26.079 119.102 24.929H115.583C116.043 27.988 118.297 29.368 121.425 29.368C124.645 29.368 126.899 27.689 126.899 25.251C126.899 20.352 119.24 22.491 119.24 20.053Z" />
    <path d="M135.569 23.733C135.569 25.32 134.695 26.516 133.338 26.516C132.096 26.516 131.498 25.734 131.498 24.147V16.626H127.979V24.86C127.979 27.942 129.704 29.368 132.05 29.368C133.545 29.368 134.764 28.724 135.569 27.344V29H139.088V16.626H135.569V23.733Z" />
    <path d="M140.788 29H144.307V21.893C144.307 20.306 145.112 19.087 146.331 19.087C147.458 19.087 148.01 19.892 148.01 21.456V29H151.506V21.893C151.506 20.306 152.334 19.087 153.553 19.087C154.68 19.087 155.209 19.892 155.209 21.456V29H158.728V20.743C158.728 17.684 157.026 16.235 154.726 16.235C153.185 16.235 151.874 16.879 151.023 18.282C150.402 16.902 149.16 16.235 147.688 16.235C146.262 16.235 145.089 16.856 144.307 18.19V16.626H140.788V29Z" />
    <path
      d="M40.8504 34.0526C44.6624 30.6486 47 26.0548 47 21C47 10.5066 36.9264 2 24.5 2C12.0736 2 2 10.5066 2 21C2 31.4934 12.0736 40 24.5 40C28.0253 40 31.3613 39.3154 34.3316 38.0949C37.8208 40.2486 42.0957 41.7967 46.8169 41.4702C44.3227 40.8362 42.1773 38.2679 40.8504 34.0526Z"
      fill="#B6EFA2"
    />
    <path
      d="M41 21C41 28.732 33.6127 35 24.5 35C15.3873 35 8 28.732 8 21C8 13.268 15.3873 7 24.5 7C33.6127 7 41 13.268 41 21Z"
      fill="#88E169"
    />
    <path
      d="M35 21C35 25.9706 30.299 30 24.5 30C18.701 30 14 25.9706 14 21C14 16.0294 18.701 12 24.5 12C30.299 12 35 16.0294 35 21Z"
      fill="#55AE36"
    />
  </chakra.svg>
)